/**
 * 💣 Remember to keep those enums values ordered alphabetically
 */

export enum IconFill {
  AccountTree = 'account_tree-fill',
  Add = 'add-fill',
  AddChart = 'add_chart-fill',
  AddComment = 'add_comment-fill',
  AddListAlt = 'add_list_alt-fill',
  AddPhotoAlt = 'add_photo_alternate-fill',
  AdminPanelSettings = 'admin_panel_settings-fill',
  AdsClick = 'ads_click-fill',
  ArrowBack = 'arrow_back-fill',
  ArrowBackIos = 'arrow_back_ios-fill',
  ArrowDropDown = 'arrow_drop_down-fill',
  ArrowDropUp = 'arrow_drop_up-fill',
  ArrowForward = 'arrow_forward-fill',
  ArrowForwardIos = 'arrow_forward_ios-fill',
  Article = 'newspaper-fill',
  AudienceNetwork = 'audience_network-fill',
  Autorenew = 'autorenew-fill',
  BarChart = 'bar_chart-fill',
  Bookmark = 'bookmark-fill',
  Cached = 'cached-fill',
  Campaign = 'campaign-fill',
  Cancel = 'cancel-fill',
  Carousel = 'view_carousel-fill',
  Celebration = 'celebration-fill',
  Check = 'check-fill',
  CheckCircle = 'check_circle-fill',
  Close = 'close-fill',
  ContentCopy = 'content_copy-fill',
  CreditCard = 'credit_card-fill',
  DarkMode = 'dark_mode-fill',
  Dashboard = 'dashboard-fill',
  DateRange = 'date_range-fill',
  Delete = 'delete-fill',
  Description = 'description-fill',
  DesignServices = 'design_services-fill',
  Diamond = 'diamond-fill',
  DragIndicator = 'drag_indicator-fill',
  Edit = 'edit-fill',
  Error = 'error-fill',
  Event = 'event-fill',
  Facebook = 'facebook-fill',
  FacebookMessenger = 'facebook_messenger-fill',
  Favorite = 'favorite-fill',
  FileDownload = 'download-fill',
  FileUpload = 'upload-fill',
  FilterAlt = 'filter_alt-fill',
  FilterAltOff = 'filter_alt_off-fill',
  Flag = 'flag-fill',
  FolderOpen = 'folder_open-fill',
  FormatQuote = 'format_quote-fill',
  Forum = 'forum-fill',
  GppBad = 'gpp_bad-fill',
  GppMaybe = 'gpp_maybe-fill',
  Group = 'group-fill',
  Help = 'help-fill',
  HideImage = 'hide_image-fill',
  Home = 'home-fill',
  Image = 'image-fill',
  Info = 'info-fill',
  InsertChart = 'insert_chart-fill',
  Instagram = 'instagram-fill',
  Job = 'work-fill',
  Label = 'label-fill',
  LightMode = 'light_mode-fill',
  Link = 'link-fill',
  Linkedin = 'linkedin-fill',
  List = 'list-fill',
  LiveTv = 'live_tv-fill',
  Lock = 'lock-fill',
  Login = 'login-fill',
  Logout = 'logout-fill',
  LucideArrowDown = 'lucide_arrow_down-fill',
  LucideArrowUp = 'lucide_arrow_up-fill',
  Mail = 'mail-fill',
  Menu = 'menu-fill',
  MergeType = 'merge_type-fill',
  MoreVert = 'more_vert-fill',
  NoteAdd = 'note_add-fill',
  OpenInNew = 'open_in_new-fill',
  Percent = 'percent-fill',
  Person = 'person-fill',
  PieChart = 'pie_chart-fill',
  PlayArrow = 'play_arrow-fill',
  Poll = 'how_to_vote-fill',
  PostAdd = 'post_add-fill',
  RadioButtonChecked = 'radio_button_checked-fill',
  RadioButtonUnchecked = 'radio_button_unchecked-fill',
  Reply = 'reply-fill',
  ReplyAll = 'reply_all-fill',
  RocketLaunch = 'rocket_launch-fill',
  Save = 'save-fill',
  SaveAs = 'save_as-fill',
  Schedule = 'schedule-fill',
  Search = 'search-fill',
  Sentiment = 'ar_on_you-fill',
  SentimentMixed = 'sentiment_calm-fill',
  SentimentNegative = 'sentiment_dissatisfied-fill',
  SentimentNeutral = 'sentiment_neutral-fill',
  SentimentPositive = 'sentiment_excited-fill',
  SentimentSettings = 'sentiment_settings-fill',
  SentimentUnclassified = 'sentiment_unclassified-fill',
  Settings = 'settings-fill',
  Share = 'share-fill',
  ShowChart = 'show_chart-fill',
  Sort = 'sort-fill',
  SpaceDashboard = 'space_dashboard-fill',
  Speed = 'speed-fill',
  SupportAgent = 'support_agent-fill',
  Swords = 'swords-fill',
  ThumbUp = 'thumb_up-fill',
  Title = 'title-fill',
  TrendingDown = 'trending_down-fill',
  TrendingFlat = 'trending_flat-fill',
  TrendingUp = 'trending_up-fill',
  Tune = 'tune-fill',
  TurnRight = 'turn_right-fill',
  Verified = 'verified-fill',
  VideoCam = 'videocam-fill',
  Visibility = 'visibility-fill',
  VisibilityOff = 'visibility_off-fill',
  Wallet = 'wallet-fill',
  Warning = 'warning-fill',
  Whatsapp = 'whatsapp-fill',
}

/**
 * Icons that are outlined
 */
export enum Icon {
  AccountTree = 'account_tree',
  Add = 'add',
  AddChart = 'add_chart',
  AddComment = 'add_comment',
  AddListAlt = 'add_list_alt',
  AddPhotoAlt = 'add_photo_alternate',
  AdminPanelSettings = 'admin_panel_settings',
  AdsClick = 'ads_click',
  ArrowBack = 'arrow_back',
  ArrowBackIos = 'arrow_back_ios',
  ArrowDropDown = 'arrow_drop_down',
  ArrowDropUp = 'arrow_drop_up',
  ArrowForward = 'arrow_forward',
  ArrowForwardIos = 'arrow_forward_ios',
  Article = 'newspaper',
  AudienceNetwork = 'audience_network',
  Autorenew = 'autorenew',
  BarChart = 'bar_chart',
  Bookmark = 'bookmark',
  Cached = 'cached',
  Campaign = 'campaign',
  Cancel = 'cancel',
  Carousel = 'view_carousel',
  Celebration = 'celebration',
  Check = 'check',
  CheckCircle = 'check_circle',
  Close = 'close',
  ContentCopy = 'content_copy',
  CreditCard = 'credit_card',
  DarkMode = 'dark_mode',
  Dashboard = 'dashboard',
  DateRange = 'date_range',
  Delete = 'delete',
  Description = 'description',
  DesignServices = 'design_services',
  Diamond = 'diamond',
  DragIndicator = 'drag_indicator',
  Edit = 'edit',
  Error = 'error',
  Event = 'event',
  Facebook = 'facebook',
  FacebookMessenger = 'facebook_messenger',
  Favorite = 'favorite',
  FileDownload = 'download',
  FileUpload = 'upload',
  FilterAlt = 'filter_alt',
  FilterAltOff = 'filter_alt_off',
  Flag = 'flag',
  FolderOpen = 'folder_open',
  FormatQuote = 'format_quote',
  Forum = 'forum',
  GppBad = 'gpp_bad',
  GppMaybe = 'gpp_maybe',
  Help = 'help',
  HideImage = 'hide_image',
  Home = 'home',
  Image = 'image',
  Info = 'info',
  InsertChart = 'insert_chart',
  Instagram = 'instagram',
  Job = 'work',
  Label = 'label',
  LightMode = 'light_mode',
  Link = 'link',
  Linkedin = 'linkedin',
  List = 'list',
  LiveTv = 'live_tv',
  Lock = 'lock',
  Login = 'login',
  Logout = 'logout',
  Mail = 'mail',
  Menu = 'menu',
  MergeType = 'merge_type',
  MoreVert = 'more_vert',
  NoteAdd = 'note_add',
  OpenInNew = 'open_in_new',
  People = 'group',
  Percent = 'percent',
  Person = 'person',
  PieChart = 'pie_chart',
  PlayArrow = 'play_arrow',
  Poll = 'how_to_vote',
  PostAdd = 'post_add',
  RadioButtonChecked = 'radio_button_checked',
  RadioButtonUnchecked = 'radio_button_unchecked',
  Reply = 'reply',
  ReplyAll = 'reply_all',
  RocketLaunch = 'rocket_launch',
  Save = 'save',
  SaveAs = 'save_as',
  Schedule = 'schedule',
  Search = 'search',
  Sentiment = 'ar_on_you',
  SentimentMixed = 'sentiment_calm',
  SentimentNegative = 'sentiment_dissatisfied',
  SentimentNeutral = 'sentiment_neutral',
  SentimentPositive = 'sentiment_excited',
  SentimentSettings = 'sentiment_settings',
  SentimentUnclassified = 'sentiment_unclassified',
  Settings = 'settings',
  Share = 'share',
  ShowChart = 'show_chart',
  Sort = 'sort',
  SpaceDashboard = 'space_dashboard',
  Speed = 'speed',
  SupportAgent = 'support_agent',
  Swords = 'swords',
  ThumbUp = 'thumb_up',
  Title = 'title',
  TrendingDown = 'trending_down',
  TrendingFlat = 'trending_flat',
  TrendingUp = 'trending_up',
  Tune = 'tune',
  TurnRight = 'turn_right',
  Verified = 'verified',
  VideoCam = 'videocam',
  Visibility = 'visibility',
  VisibilityOff = 'visibility_off',
  Wallet = 'wallet',
  Warning = 'warning',
  WhatsApp = 'whatsapp',
}
